export const musicControlPanelReducer = (state = {}, action) => {
  switch (action.type) {
    case 'MUSIC_CONTROL_PANEL_ACTION1':
      return action.arg1;
    case 'MUSIC_CONTROL_PANEL_ACTION2':
      return {};
    default:
      return state;
  }
};

export default musicControlPanelReducer