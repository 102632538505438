import { connect } from 'react-redux';
import { action1, action2 } from './musicControlPanelAction';
import { MusicControlPanel } from './musicControlPanel'

const mapStateToProps = state => ({
  arg1: state.arg1,
});

const mapDispatchToProps = {
  action1,
  action2,
};

export default connect(mapStateToProps, mapDispatchToProps)(MusicControlPanel);
