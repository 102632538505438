import './musicControlPanel.css'
import React from 'react';
import { Container, Button, Select, InputLabel, MenuItem, Box, FormControl, FormLabel, RadioGroup, FormControlLabel, Radio } from '@material-ui/core'

export class MusicControlPanel extends React.Component {
  render() {
    return (
      <Container className="baseContainer">
        <FormControl component="fieldset">
          <FormLabel component="legend">Mood</FormLabel>
          <RadioGroup defaultValue="A" name="customized-radios">
            <FormControlLabel value="A" control={<Radio />} label="A" />
            <FormControlLabel value="B" control={<Radio />} label="B" />
            <FormControlLabel value="C" control={<Radio />} label="C" />
            <FormControlLabel value="D" control={<Radio />} label="D" />
            <FormControlLabel value="E" control={<Radio />} label="E" />
            <FormControlLabel value="F" control={<Radio />} label="F" />
            <FormControlLabel value="G" control={<Radio />} label="G" />
            <FormControlLabel value="H" control={<Radio />} label="H" />
            <FormControlLabel value="I" control={<Radio />} label="I" />
            <FormControlLabel value="J" control={<Radio />} label="J" />
            <FormControlLabel value="K" control={<Radio />} label="K" />
            <FormControlLabel value="L" control={<Radio />} label="L" />
          </RadioGroup>
        </FormControl>
        <Box className="bottomBox">
          <Button variant="contained" color="primary">Create Music</Button>
        </Box>
      </Container>
    )
  }
}

export default MusicControlPanel